import React, { useState } from "react"
import styled from "styled-components"
import { mobileS, mobileM, mobileL, tablet, desktop } from "../responsive"
import GoogleLogoIcon from "../images/GoogleLogoIcon.component"
import LogoIcon from "../images/LogoIcon.component"
import Seo from "../components/seo"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import Img from "../images/LoginBg2.jpg"
import axios from "axios"
import { navigate } from "gatsby"
import useAuthState from "../stores/auth"

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: "flex",
    alignContent: "center",
    // backgroundImage: `url(${Img})`,
    // backgroundPosition: "initial",
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    backgroundColor:"#edfff3cc",
    width: "100vw",
    // height: "100vh",
    height: "100%",
    paddingTop:"1rem",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  signin: {
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "60%",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      width: "90%",
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90%",
      justifyContent: "center",
    },
    [theme.breakpoints.up("xl")]: {
      width: "90%",
      justifyContent: "center",
    },
  },
  card: {
    flex: 1,
    borderRadius: "10px",
    boxShadow: "0px 10px 20px #a3d4cb",
    padding: "20px",
  },
  header: {
    textAlign: "center",
  },
  body: {},
  button: {
    marginTop: "10px",
    textAlign: "center",
  },
  register: {
    marginTop: "10px",
  },
  link: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  google: {
    width: "100%",
    padding: "15px 0",
    display: "flex",
    justifyContent: "center",
  },
  google__button: {
    padding: "6px 16px",
    border: "1px solid #a3d4cb",
    borderRadius: "3px",
    fontWeight: 600,
    lineHeight: 1.75,
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  googleIcon: {
    width: "100px",
    height: "40px",
  },
  input: {
    padding: " 20px 0 ",

    "& :valid": {
      color: "#1d9682",
    },
    "& :invalid": {
      color: "red",
    },
  },
}))
const LeftContainer = styled.div`
  flex: 2;
  ${mobileS({ display: "none" })}
  ${mobileM({ display: "none" })}
  ${desktop({ display: "none" })}
  ${desktop({ display: "unset" })}
`
const RightContainer = styled.div`
  flex: 1;

  ${mobileS({ padding: "10px" })}
  ${mobileM({ padding: "10px" })}
  ${mobileL({ padding: "10px" })}
  ${tablet({ display: "flex", justifyContent: "center" })}
  ${desktop({ right: "30px", padding: "10px" })}
`
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#a3d4cb",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#a3d4cb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#a3d4cb",
      },
    },
  },
})(TextField)

const RegisterBtn = styled(Button)`
  background: #1d9682;
  width: 100%;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  white-space: nowrap;
  padding: 10px 22px;
  color: white;
  font-family: "Oswald";
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: "center";
  align-items: center;

  &:hover {
    transition: all 0.2sease-in-out;
    background: #f5f5f5;
    color: #205072;
  }
`
const LoginContainer = styled.div``

const LoginImg = styled.div`
  position: absolute;

  left: 142px;
  top: 255px;
  //background: rgb(72 67 67 / 20%);
  background: #000;
  backdrop-filter: blur(5px);
  width: 500px;
  height: 350px;
  align-self: center;
  border-radius: 10px;

  ${mobileS({ display: "none" })}
  ${mobileM({ display: "none" })}
  ${tablet({ display: "none" })}
  ${desktop({ display: "unset", width: "500px", height: "250px" })}
`
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`

const Title = styled.h4`
  font-family: Oswald;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 53px;
  letter-spacing: 0.04em;

  color: #ffffff;
  ${desktop({
    fontFamily: "Oswald",
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "30px",
    lineHeight: "60px",
    letterSpacing: "0.04em",
    color: "#ffffff",
  })}
`
const SubTitle = styled.p`
  font-family: Oswald;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #ffffff;
  ${desktop({
    fontFamily: " Oswald",
    fontStyle: "normal",
    fontWeight: "lighter",
    fontSize: "25px",
    lineHeight: "50px",
    letterSpacing: "0.04em",
    color: "#ffffff",
  })}
`

const Desc = styled.p`
  width: 456px;
  height: 123px;
  font-family: Oswald;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.04em;

  color: #ffffff;
  ${desktop({
    width: "364px",
    height: "123px",
    fontFamily: "Oswald",
    fontStyle: "italic",
    fontWeight: "100",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.04em",
    color: "#ffffff",
  })}
`

export default function Register() {
  const { logIn } = useAuthState()

  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [agree, setAgree] = useState(false);


  const handleRegister = async () => {
    setError("")
    setSuccess("")
    //error handling and validation for first name.....x
    if (!password) {
      return setError("Password is required")
    }
    if (password.length < 6) {
      return setError("Password must be at least 6 characters")
    }
    if (!confirm) {
      return setError("Confirm password is required")
    }
    if (password !== confirm) {
      return setError("Password and confirm password do not match")
    } else {
      try {
        setIsLoading(true)

        const result = await axios.post(
          `${process.env.GATSBY_API_URL}/users/register`,
          {
            firstName,
            lastName,
            phoneNumber,
            email: email.toLowerCase(),
            password,
            confirm,
          }
        )

        setIsLoading(false)
        logIn(result.data)
        navigate(-2)
      } catch (error) {
        setIsLoading(false)
        const message = error.response.data.message
        setError(message)
      }
    }
  }

  return (
    <>
      <Grid container className={classes.root}>
      <Seo
        title="Afyabook Register"
        description="Register on Afyabook using your Google or email address"
        siteUrl="https://www.afyabook.com/Register"
        canonical="https://www.afyabook.com/Register"
      />
      <LeftContainer>
        <LoginContainer>
          <LoginImg>
            <TextWrapper>
              <Title>Welcome to Afyabook</Title>
              <SubTitle>Health is Wealth</SubTitle>
              <Desc>
                “Get medicine, consult doctors, monitor your vitals, keep your health record”
              </Desc>
            </TextWrapper>
          </LoginImg>
        </LoginContainer>
      </LeftContainer>
      <RightContainer>
        <Paper className={classes.signin}>
          <Grid className={classes.card}>
            <Grid className={classes.header}>
              <Typography
                variant="h6"
                color="black"
                align="center"
                style={{ margin: "10px 0", fontFamily: "Oswald" }}
              >
                Register with
              </Typography>
            </Grid>
            <Grid className={classes.body} spacing={3}>
              <Grid className={classes.google}>
                <form action={`${process.env.GATSBY_API_URL}/users/auth/google`}>
                  <Button className={classes.google__button} type="submit">
                    <GoogleLogoIcon className={classes.googleIcon} />
                  </Button>
                </form>
              </Grid>
              <Typography
                align="center"
                variant="h6"
                color="black"
                style={{ margin: "10px 0", fontFamily: "Oswald" }}
              >
                Or
              </Typography>
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              <p style={{ color: "green", textAlign: "center" }}>{success}</p>
              <form style={{ padding: "10px 0" }}>
                <CssTextField
                  required
                  id="FirstName"
                  name="FirstName"
                  label="First name"
                  className={classes.input}
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  fullWidth
                  autoComplete="fname"
                  style={{ fontFamily: "Oswald" }}
                />
                <CssTextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  fullWidth
                  className={classes.input}
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  autoComplete="lname"
                  style={{ fontFamily: "Oswald" }}
                />
                <CssTextField
                  required
                  className={classes.input}
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  label="Your Phone number"
                  fullWidth
                />
                <CssTextField
                  label="Email"
                  className={classes.input}
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type="email"
                  autoComplete={"off"}
                  required
                  fullWidth
                />
                <CssTextField
                  label="Password"
                  name="password"
                  className={classes.input}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  autoComplete="off"
                  required
                  inputProps={{ minLength: 6 }}
                  type="password"
                  fullWidth
                />
                <CssTextField
                  label="Confirm Password"
                  autoComplete="off"
                  className={classes.input}
                  name="confirm"
                  value={confirm}
                  onChange={e => setConfirm(e.target.value)}
                  required
                  inputProps={{ minLength: 6 }}
                  type="password"
                  fullWidth
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agree}
                      onChange={e => setAgree(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Agree to terms and conditions"
                />
                <p
                  style={{
                    color: "green",
                    textAlign: "center",
                    fontSize: "1.2rem",
                  }}
                >
                  {isLoading && "Loading..."}
                </p>
                <RegisterBtn
                  onClick={() => handleRegister()}
                  fullWidth
                  className={classes.button}
                  variant="contained"
                  disabled={!email || !password || !confirm || isLoading || !agree}
                >
                  Register
                </RegisterBtn>
              </form>
              <Typography
                className={classes.register}
                variant="body2"
                align="right"
                style={{ fontFamily: "Oswald" }}
              >
                Already have an account? Sign in <Link to="/Login">here</Link>.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </RightContainer>
    </Grid>
    </>
  )
}
